/* Google Map Autocomplete Custom styles */
.pac-logo:after {
  content: none;
}

.pac-container {
  background-color: white;
  box-shadow: rgba(203, 203, 203, 0.5) 0px 2px 4px 0px;
 }

 .pac-item {
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    border-left: 2px solid white;
    border-bottom: none;
    border-top: none;
  }

  .pac-item-query {
    font-size: 1rem;
  }

 .pac-item:hover {
  background-color: white;
  color: rgb(20, 74, 56);
  border-left: 2px solid rgb(20, 74, 56);
 }

 .pac-item-query:hover {
  color:rgb(20, 74, 56);
 }
