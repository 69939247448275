@font-face {
  font-family: 'Buenos Aires';
  src: url(../fonts/BuenosAires-Light.ttf),
    url(../fonts/BuenosAires-Light.woff) format("woff"),
    url(../fonts/BuenosAires-Light.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Buenos Aires';
  src: url(../fonts/BuenosAires-Regular.ttf),
    url(../fonts/BuenosAires-Regular.woff) format("woff"),
    url(../fonts/BuenosAires-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Buenos Aires';
  src: url(../fonts/BuenosAires-SemiBold.ttf),
    url(../fonts/BuenosAires-SemiBold.woff) format("woff"),
    url(../fonts/BuenosAires-SemiBold.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Buenos Aires';
  src: url(../fonts/BuenosAires-Bold.ttf),
    url(../fonts/BuenosAires-Bold.woff) format("woff"),
    url(../fonts/BuenosAires-Bold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
}
