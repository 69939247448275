html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus {
  outline: rgb(82, 81, 153) solid 3px;
  outline-offset: 0.125em;
}
